<template>
<div style="max-width: 800px">

  <div class="card-box">
    <h4>Recently Updated</h4>
    <p>Messages whose reputation were recently updated</p>
    <CButton @click="loadPath('sms-reputation/recently-updated')" color="info" class="card-box-button">
      Recently Updated
    </CButton>
  </div>

  <div class="card-box">
    <h4>Conflicting Reputations</h4>
    <p>Messages whose reputation does not match the sms-filters decision</p>
    <CButton @click="loadPath('sms-reputation/conflicting-reputations')" color="info" class="card-box-button">
      Conflicting Reputations
    </CButton>
  </div>


</div>
</template>

<script>
export default {
  name: 'SMS-Reputation',
  methods: {
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path
      });
    },
  }
}
</script>
